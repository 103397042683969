import { createApp } from 'vue'
import { onAuthStateChanged } from 'firebase/auth';
import { projectAuth } from './firebase/config'
import App from './App.vue'
import router from './router'
import './assets/index.css'

let app

onAuthStateChanged(projectAuth, () => {
    if (!app) {
        app = createApp(App).use(router).mount('#app')
    }
})