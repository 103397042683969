import { ref } from 'vue'
import { onAuthStateChanged } from 'firebase/auth'
import { projectAuth } from '../firebase/config'

const user = ref(projectAuth.currentUser)

onAuthStateChanged(projectAuth, (fbUser) => {
  user.value = fbUser
})

const getUser = () => {
  return { user }
}

const checkAuth = (to, from, next) => {
  const { user } = getUser()
  
  if (!user.value) {
    if (to.name === 'Login' || to.name === 'Signup') {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    if (to.name === 'Home' || to.name === 'Login' || to.name === 'Signup') {
      next({ name: 'Queue' })
    } else {
      next()
    }
  }
}

export { getUser, checkAuth }