import { createRouter, createWebHistory } from 'vue-router'
import { checkAuth } from '../composables/getAuth'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: checkAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/Login.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Auth/Signup.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/queue',
    name: 'Queue',
    component: () => import('../views/Queue.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: () => import('../views/Booking/List.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import('../views/Client/List.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('../views/Invoice/List.vue'),
    beforeEnter: checkAuth
  },
  {
    path: '/booking/:id',
    name: 'Booking',
    component: () => import('../views/Booking/Overview.vue'),
    beforeEnter: checkAuth,
    props: true
  },
  {
    path: '/client/:id',
    name: 'Client',
    component: () => import('../views/Client/Overview.vue'),
    beforeEnter: checkAuth,
    props: true
  },
  {
    path: '/invoice/:id',
    name: 'Invoice',
    component: () => import('../views/Invoice/Overview.vue'),
    beforeEnter: checkAuth,
    props: true
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/queue'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }

  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
