import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
	apiKey: "AIzaSyDSzUi3xnam21mSaFbiMTo4RydgZ6VmkTQ",
	authDomain: "automaster-centre.firebaseapp.com",
	projectId: "automaster-centre",
	storageBucket: "automaster-centre.appspot.com",
	messagingSenderId: "785436332708",
	appId: "1:785436332708:web:f043e86cf446ce5da20c31",
	databaseURL: "https://automaster-centre-default-rtdb.europe-west1.firebasedatabase.app/"
}

const firebaseApp = initializeApp(firebaseConfig)
const projectAuth = getAuth(firebaseApp)
const projectFirestore = getFirestore(firebaseApp)
const projectDB = getDatabase(firebaseApp)

export { projectAuth, projectFirestore, projectDB }