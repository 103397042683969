<template>
  <Sidebar v-if="user" />
  <div class="content" :class="{ main: user }">
    <router-view/>
  </div>
</template>

<script>
import { getUser } from './composables/getAuth'
import Sidebar from './components/Main/Sidebar.vue'

export default {
  components: { Sidebar },
  setup() {
    const { user } = getUser()

    return { user }
  }
}
</script>