import { ref } from 'vue'
import { signInWithEmailAndPassword, sendPasswordResetEmail, 
  createUserWithEmailAndPassword, updateProfile, signOut } from 'firebase/auth'
import { projectAuth } from '../firebase/config'
import { Toast } from './useSwal'

const error = ref(null)
const isLoading = ref(false)

const login = async (email, password) => {
  error.value = null
  isLoading.value = true

  try {
    await signInWithEmailAndPassword(projectAuth, email, password)
    error.value = null
    isLoading.value = false
  } catch(err) {
    error.value = 'Oops... Incorrect login credentials'
    isLoading.value = false
    
    Toast.fire({
      title: 'Uh-oh...',
      text: error.value,
      icon: 'error'
    })
  }
}

const signup = async (firstName, email, password) => {
  error.value = null
  isLoading.value = true

  try {
    const res = await createUserWithEmailAndPassword(projectAuth, email, password)
    await updateProfile(res.user, { displayName: firstName })
    error.value = null
    isLoading.value = false
  } catch(err) {
    const errMessage = err.code == 'auth/weak-password' 
      ? 'Password must be at least 6 characters long'
      : err.code?.split('/')[1]?.replace(/-/g, ' ') || err.message

    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
    isLoading.value = false

    Toast.fire({
      title: 'Uh-oh...',
      text: error.value,
      icon: 'error'
    })
  }
}

const logout = async () => {
  error.value = null
  isLoading.value = true

  try {
    await signOut(projectAuth)
    error.value = null
    isLoading.value = false
  } catch(err) {
    const errMessage = err.code?.split('/')[1]?.replace(/-/g, ' ') || err.message
    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
    isLoading.value = false

    Toast.fire({
      title: 'Uh-oh...',
      text: error.value,
      icon: 'error'
    })
  }
}

const passReset = async (email) => {
  error.value = null
  isLoading.value = true

  try {
    await sendPasswordResetEmail(projectAuth, email)
    error.value = null
    isLoading.value = false

    Toast.fire({
      title: 'Success!',
      text: 'Your password reset link was sent successfully',
      icon: 'success'
    })
  } catch(err) {
    const errMessage = err.code?.split('/')[1]?.replace(/-/g, ' ') || err.message
    error.value = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
    isLoading.value = false

    Toast.fire({
      title: 'Uh-oh...',
      text: error.value,
      icon: 'error'
    })
  }
}

const useAuth = () => {
  return { error, isLoading, login, signup, logout, passReset }
}

export default useAuth