<template>
  <aside class="sidebar"> 
    <router-link class="company-logo" :to="{ name: 'Queue' }">Auto Master Centre</router-link>
    <nav class="sidebar-links">
      <router-link class="link" :to="{ name: 'Queue' }">
        <i class="fas fa-home"></i>
        <span>Queue</span>
      </router-link>
      <router-link class="link" :to="{ name: 'Bookings' }">
        <i class="fas fa-car"></i>
        <span>Bookings</span>
      </router-link>
      <router-link class="link" :to="{ name: 'Clients' }">
        <i class="fas fa-user"></i>
        <span>Clients</span>
      </router-link>
      <router-link class="link" :to="{ name: 'Invoices' }">
        <i class="fas fa-file-alt"></i>
        <span>Invoices</span>
      </router-link>
      <span @click="handleLogout" @keyup.enter="handleLogout" class="link" tabindex="0">
        <i class="fas fa-sign-out-alt"></i>
        <span>Log Out</span>
      </span>
    </nav>
    <div class="mobile-menu">
      <router-link :to="{ name: 'Queue' }" class="btn"><i class="fas fa-home"></i></router-link>
      <button id="menu-btn" @click="showMenu = !showMenu">
          <i v-if="!showMenu" class="fas fa-bars"></i>
          <i v-else class="fas fa-times"></i>
      </button>
    </div>
    <div class="dropdown-menu" :class="{ shown: showMenu }">
      <ul>
        <li @click="handleRoute('Bookings')"><i class="fas fa-car"></i><span>Bookings</span></li>
        <li @click="handleRoute('Clients')"><i class="fas fa-user"></i><span>Clients</span></li>
        <li @click="handleRoute('Invoices')"><i class="fas fa-file-alt"></i><span>Invoices</span></li>
        <li @click="handleLogout"><i class="fas fa-sign-out-alt"></i><span>Logout</span></li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useAuth from '@/composables/useAuth'

export default {
  setup() {                                       
    const { logout, error } = useAuth();
    const router = useRouter();
    const showMenu = ref(false)

    const handleRoute = (page) => {
      showMenu.value = false
      router.push({ name: page })
    }

    const handleLogout = async () => {
      showMenu.value = false
      await logout();

      if (!error.value) {
        router.push({ name: 'Login' })
      }
    }

    return { handleLogout, handleRoute, showMenu }
  }
}
</script>

<style>
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 150px;
    height: 100%;
    background-color: var(--main);
    z-index: 991;
  }
  .company-logo {
    display: block;
    text-decoration: none;
    padding: 15px 5px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    color: #fff;
  }
  .company-logo:hover {
    color: var(--primary);
  }
  .company-logo .mobile {
    display: none;
    width: 80%;
  }
  .sidebar-links {
    position: absolute;
    width: 90%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10%;
  }
  .sidebar .link {
    display: block;
    text-decoration: none;
    margin: 0;
    padding: 20px 0;
    color:  #fff;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    border-radius: 20px 0 0 20px;
  }
  .sidebar .link i {
    font-size: 18px;
    margin: 0 15px 0 30px;
    color: #fff;
  }
  .sidebar .link span {
    font-size: 12px;
    color: #fff;
  }
  .sidebar .link.danger i, .sidebar .link.danger span {
    color: red;
  }
  .sidebar .link.usage {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px 10px 10px 10px;
    border: none;
    background-color: var(--primary);
    cursor: help;
  }
  .sidebar .link.usage i:not(.fa-info-circle) {
    color: #fff;
    font-size: 20px;
  }
  .sidebar-links .link:hover {
    background-color: rgba(25, 25, 25, 0.1);
  }
  .sidebar-links .link.router-link-active {
    background-color: var(--bg);
  }
  .sidebar-links .link.router-link-active i, .sidebar-links .link.router-link-active span {
    color: var(--main);
  }
  .sidebar-links .link.danger.router-link-active {
    border-left: 3px solid red;
  }
  .sidebar-links .link.danger.router-link-active i, .sidebar-links .link.danger.router-link-active span {
    color: red;
  }
  .tooltip.usage h5 {
    font-size: 14px;
    margin: 5px auto;
    color: #fff;
  }
  .tooltip.usage span {
    font-size: 13px; 
    color: #fff;
  }
  .tooltip.usage.unlimited span {
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
  }
  .tooltip.usage i {
    font-size: 14px;
    color: #fff;
  }
  .tooltip.usage progress {
    width: 100%;
  }
  .sidebar .tooltip {
    visibility: hidden;
    position: absolute;
    background: var(--primary);
    border-radius: 10px;
    padding: 5px 10px;
    left: 50px;
    font-size: 14px;
    color: #fff;
  }
  .sidebar .tooltip.sub::after, .sidebar .tooltip.usage::after {
    content: " ";
    position: absolute;
    top: 30px;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--primary) transparent transparent;
  }
  .sidebar .tooltip.usage::after {
    top: 55px;
  }
  .sidebar .tooltip.usage.unlimited::after {
    top: 40px;
  }
  .sidebar .tooltip.sub::after {
    border-color: transparent red transparent transparent;
  }
  .sidebar .tooltip.sub, .sidebar .tooltip.usage {
    margin-top: -60px;
    left: 85px;
  }
  .sidebar .tooltip.sub {
    width: 200px;
    background-color: red;
  }
  .sidebar .tooltip.usage {
    width: 160px;
    margin-top: -70px;
  }
  .sidebar .tooltip.usage.unlimited {
    margin-top: -55px;
  }
  .sidebar .tooltip.logout {
    width: 80px;
  }
  .sidebar .link:hover .tooltip {
    visibility: visible;
  }
  .sidebar .mobile-menu {
    display: none;
    text-align: center;
  }
  .mobile-menu button, .mobile-menu .btn {
    margin: 0 5px;
    padding: 6px 10px;
    font-size: 17px;
  }
  .mobile-menu button i, .mobile-menu .btn i {
    color: #fff;
  }
  .dropdown-menu {
    visibility: hidden;
    opacity: 0;
    width: max-content;
    min-width: 150px;
    max-width: 200px;
    position: fixed;
    top: 50px;
    right: 0;
    background-color: var(--main);
    border-radius: 10px;
    box-shadow: 0 2px 5px 2px rgba(0,0,0,0.2);
    z-index: 999;
    transition: all 0.6s ease;
    transform: translateX(20px);
  }
  .dropdown-menu.shown {
    visibility: visible;
    opacity: 1;
    transform: translateX(-20px);
  }
  .dropdown-menu ul {
    padding-left: 0;
    list-style-type: none;
  }
  .dropdown-menu li {
    padding: 20px;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    cursor: pointer;
  }
  .dropdown-menu li:hover {
    background-color: var(--main-hover);
  }
  .dropdown-menu li i {
    margin-right: 10px;
    width: 20px;
  }
  .dropdown-menu li.usage {
    padding: 10px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--main);
    cursor: auto;
    text-align: center;
  }
  .dropdown-menu li.usage i {
    margin: 0;
    width: auto;
  }
  .dropdown-menu li.usage h5 {
    font-size: 15px;
  }
  .dropdown-menu li.usage span {
    font-size: 14px;
  }
  .dropdown-menu li.usage.sub {
    background-color: red;
  }
  .dropdown-menu li.usage.sub span {
    font-size: 12px;
    color: #fff;
  }
  .dropdown-menu li:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
</style>